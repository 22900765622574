.background{
    background: #beb6b612;
}

.header{
    border-radius: 4px;
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-content{
    height: 10vh;
    width: 90vw;
    background-color: white;
    display: grid;
}

.parent{
    width: 90vw;
    margin: 0 auto;
}

.body{
    height: 76vh;
    display: flex;
    justify-content: center;
    object-fit: cover;
    padding: 1em;
}

.w-90 {
    width: 90%;
}
.bottom{
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.next-button{
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    z-index: 12;
}
.selected-button{
    background: #FF8000;
    border: 1px solid #FF8000;
    box-sizing: border-box;
    height: 10vh;
    width: 100%;
    border-radius: 4px;
    padding: 8px 18px;
    margin: 42px 0 0 0;
    /* display: flex; */
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: pre;
}
.unselected-button-share{
    background: white;
    border: 1px solid #FF8000;
    box-sizing: border-box;
    color: #FF8000;
    font-weight: 800;
    font-size: 24px;
    margin: auto 0;
    height: 10vh;
    border-radius: 4px;
    width: 100%;
    padding: 8px 18px;
    /* display: flex; */
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: pre;
}

.selected-button-text{
    color: white;
    font-size: 18px;
    font-weight: 400;
}
.phone-number-label{
    color: black;
    font-weight: 400;
    font-size: 18px;
    margin-top: 24px;
    display: flex;
    margin-bottom: 6px;
}
.phone-number{
    border-color: black;
    border-radius: 8px;
    min-height: 56px;
    font-size: 18px;
    font-weight: 500;
}
.name{
    border-color: black;
    border-radius: 8px;
    min-height: 56px;
    font-size: 18px;
    font-weight: 500;
}
.edit-number, .edit-number:hover{
    margin: 8px;
    color: #727272;
    font-size: 18px;
    margin-top: 36px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    text-align: left;
}
.back-arrow{
    margin-right: 4px;
}
.download-certificate-text, .download-certificate-text:hover{
    font-size: 18px;
    margin-top: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: #FF8000;
}
.download-arrow{
    margin-right: 4px;
}
.otp-label{
    margin: 8px;
    font-style: normal;
    font-size: 18px;
    margin-top: 18px;
}
.otp-text-box{
    border-color: #FF8000;
    border-radius: 8px;
    min-height: 42px;
    width: auto;
    margin: 8px;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 18px;
}
.whatsapp-img{
    margin: 8px;
}
.otp-label{
    font-size: 18px;
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.resend-otp, .resend-otp:hover{
    color: #727272;
    margin: 8px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
}
.resend-img{
    margin-right: 4px;
}
.pledge-update-text{
    font-style: normal;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pledge-count{
    font-weight: 800;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
}
.certificate{
    object-fit: contain;
    height: 320px;
    width: 240px;
    display: flex; 
    justify-content: center;
    background-color: white;
    background-image: url("../assets/certificate.svg");
    background-size: contain;
  }
  .certificate-name{
      margin-top: 150px;
  }
  .certificate-parent{
      height: 430px;
      width: 320px;
      margin: 18px auto;
  }

  .w-fill {
      width: -webkit-fill-available;
      text-decoration: none;
  }

  .cursor-pointer {
      cursor: pointer;
  }

  .unselected-button-share-xs > img {
    width: 20px;
}

.unselected-button-share-xs > span {
    font-size: 16px;
}

.unselected-button-share-xs {
    height: 55px;
    padding: 6px;
}

.spinner-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #00000077;
    align-content: center;
    left: 0px;
}
.spinner-border {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin-top: calc(50vh - 45px);
    color: #ff8000 !important;
}
.name-screen-title{
    color: black;
    font-weight: 400;
    font-size: 18px;
    margin-top: 18px;
    display: flex;
    text-align: left;
    margin-bottom: 6px;
}
.thankyou-img{
    width: 136px;
    height: 42px;
}
.thankyou-message{
    font-weight: 400;
    font-size: 18px;
    margin: 12px 0;
}
.whatsapp-share{
    height: 9vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    z-index: 12;
}
.whatsapp-share-button{
    background: white;
    border: 1px solid green;
    box-sizing: border-box;
    color: green;
    font-weight: 400;
    font-size: 16px;
    margin: auto 0;
    height: 10vh;
    border-radius: 4px;
    width: 100%;
    padding: 8px 18px;
    /* display: flex; */
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: pre;
}
.whatsapp-share-image{
    height: 24px;
    width: 24px;
    margin: 0 4px
}
.whatsapp-share-text{
    color: white;
    font-size: 18px;
    font-weight: 400;
}
.certificate-share-parent{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 768px) {
    .home-img{
        object-fit: contain;
        width: 100%;
        margin: 36px 0;
    }
}


@media only screen and (min-width: 768px) {
    .home-img{
        object-fit: contain;
        margin: 36px 0;
    }
}
